html {
  overflow-y: scroll;
  overflow-x: scroll;
}

img {
  cursor: pointer;
}

.App {
  text-align: center;
  overflow-y: scroll;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  top: 0; 
  width: 100%;
}

.pageSizePanel {
  overflow: hidden;
  height: 100vh;
  background-color: none;
}

.headingPanel {
  overflow: hidden;
  height: 34.91vh;
  background-color: none;
}

.headerImage {
height: 100%;
max-width: none;
min-width: 100vw;
}

.panelContentContainer {
  height: 100%;
  min-height: fit-content;
  width: 100%;
}

.pageSizePanel,
#pageSizePanelLeftRightContainer,
.panelContentContainer,
.pageSizePanelLeftRightContainer,
.pageSizePanelLeft,
.pageSizePanelRight {
  min-height: fit-content;
  overflow: hidden;
}

#page4,
#page5,
#pageSizePanel-page4,
#pageSizePanel-page5,
#page6,
#page7,
#pageSizePanel-page6,
#pageSizePanel-page7,
#page9,
#page10,
#pageSizePanel-page9, 
#pageSizePanel-page10,
#page11,
#page13,
#pageSizePanel-page11, 
#pageSizePanel-page13,
#page14,
#page15,
#pageSizePanel-page14, 
#pageSizePanel-page15,
#page16,
#page17,
#page18,
#pageSizePanel-page16, 
#pageSizePanel-page17, 
#pageSizePanel-page18 {
  background-color: rgb(0, 54, 59);
}

#darkPastContainerRightLeft {
  margin-bottom: 21%;
}

.pageSizePanelLeft {
  float: left;
  overflow: hidden;
  width: 50%;
  height: 100%;
}


.pageSizePanelRight {
  float: right;
  overflow: hidden;
  width: 50%;
  height: 100%;
}

.pageSizePanelClickToEnlarge,
#pageSizePanelLeftClickToEnlargeContainer,
.pageSizePanelLeftRightContainer {
  font-family: 'PT Sans', sans-serif;
}


.pageSizePanelLeftRightContainer {
  height: 100%;
  width: 100%;
}

#action-force-SpecialForces20-21-container {
  margin: 0 auto;
  margin-top: 1.5%;
  max-width:91%;
  max-height:100%;
  height: auto;
  overflow: hidden;
  /*outline: 2px solid rgb(235, 81, 12);*/
  line-height: 0;
}

#action-force-SpecialForces20-21-slide-1 {
  height: auto;
  /*max-width: 100%;*/
  width: 100%;
}

#pageSizePanelRightImageGridTopRow img,
#pageSizePanelRightImageGridBottomRow img,
#action-force-SpecialForces20-21-container,
#whatHappensCoverContainerLeftLeft img,
#whatHappensCoverContainerLeftRightTop img,
#whatHappensCoverContainerLeftRightBottom img,
#whatHappensCoverContainerRightTopRow img,
#whatHappensCoverContainerRightBottomRow img,
#darkPastContainerLeftRightBottom img,
#darkPastContainerRightRight img,
#darkPastContainerLeftLeft img,
#darkPastContainerLeftRightTop img,
#darkPastContainerLeftRightBottom img {
  box-shadow:rgba(255,255,255, 0.5) 0 0 9px;
}

#pageSizePanelLeftPage9ImagesContainer img,
#calligraphy4041Container img,
#hygge2627Container img,
#hygge5253hygge1011Container img,
#pageSizePanelLeftPage14ImageContainerLeft img,
#pageSizePanelLeftPage14ImageContainerRight img,
#pageSizePanelRightPage14ImageContainerLeft img,
#pageSizePanelRightPage14ImageContainerCentre img,
#pageSizePanelRightPage14ImageContainerRight img,
#coverV6ImageContainer img,
#hurstCastle23ImageContainer img,
#hurstCastle1415ImageContainer img,
#hurstCastle1617ImageContainer img,
#silburyHillBook188FinalImageContainer img,
#silburyHillBook88200FinalImageContainer img,
#seriesCoverDesigns2ImageContainer img,
#seriesCoverDesigns4ImageContainer img,
#navigatorGuidesSampleSpread1213ImageContainer img,
#navigatorGuidesSampleSpread1415ImageContainer img,
#pageSizePanelLeftpage6TopLeftImagesContainer img,
#csPartyFood3839ImageContainer img,
#infoGraphicsImagesLeftContainer img,
#infoGraphicsImagesRightContainer img,
#clovellyGuideBookRecipeBook1ImageContainer img,
#clovellyGuideBookRecipeBook2ImageContainer img,
#clovellyGuideBookRecipeBook4ImageContainer img,
#clovellyGuideBookRecipeBook5ImageContainer img,
#clovellyGuideBookRecipeBook3ImageContainer img,
#clovellyLeafletsNewsletterLeftTopContainerLeftImage img,
#clovellyLeafletsNewsletterLeftTopContainerRightImage img,
#clovellyLeafletsNewsletterRightTopContainerLeftImage img,
#clovellyLeafletsNewsletterRightTopContainerRightImage img,
#clovellyLeafletsNewsletterRightBottomContainerLeft img,
#clovellyLeafletsNewsletterRightBottomContainerRight img,
#clovellyLeafletsNewsletterLeftBottomContainerRight img {
  box-shadow:rgba(255,255,255, 0.8) 0 0 9px;
}

#silburyHillCoverFinalImageContainer img {
  box-shadow:rgba(103,112,111, 1) 0 0 11px;
}

#pageSizePanelLeftpage6BottomLeftImagesContainerRight img {
  box-shadow:rgba(164, 169, 169, 1) 0 0 11px;
}

#pageSizePanelRightImageGridTopRow,
#pageSizePanelRightImageGridBottomRow {
  margin-left: 5px;
}

#action-force-special-forces-slide-2,
#action-force-police-units-slide-3,
#action-force-armed-services-slide-4,
#action-force-border-defence-slide-5,
#action-force-air-force-slide-6,
#action-force-army-slide-7,
#action-force-special-operations-slide-8,
#action-force-navy-slide-9 {
  width: 22.9%;
}

#action-force-police-units-slide-3,
#action-force-armed-services-slide-4,
#action-force-border-defence-slide-5,
#action-force-army-slide-7,
#action-force-special-operations-slide-8,
#action-force-navy-slide-9 {
  margin-left: 2.66%;
}

#pageSizePanelRightImageGrid {
  margin-left: 5.2%;
  /*margin-top: 14%;*/
  margin-top: 14vh;
  float: left;
  width: 85%;
  line-height: 0;
  overflow: hidden;
}

#pageSizePanelRightImageGridBottomRow,
#pageSizePanelRightImageGridTopRow{
  margin-bottom: 4.5%;
  margin-top: 0.5%;
  margin-right: 0.5%;
}

#pageSizePanelLeftClickToEnlargeContainer {
  position: relative;
 /*margin-top: 16.5%;*/
  margin-top: 16.5vh;
  margin-left: 4.5%;
}

#pageSizePanelRightClickToEnlargeContainer {
  width: 43.7%;
  margin-left: 50.4%;
}

.pageSizePanelClickToEnlarge {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1.05rem;
  color: rgb(254, 236, 184);
}

#pageSizePanelRightTextBoxTop,
#pageSizePanelRightTextBoxBottom {
  display: inline-block;
  margin-left: 5.2%;
  width: 85%;
}

#pageSizePanelPage6TextBox {  
  float: right;
  display: inline-block;
  width: 12%;
  vertical-align: top;
  margin-right: -5%;
  margin-top: -11.4%;
}

#pageSizePanelPage9TextBox {        
  display: inline-block;
  width: 41%;
  margin-right: 10%;
  vertical-align: top;
  margin-top: 6.8%;
}

#pageSizePanelPage10TextBox {        
  display: inline-block;
  width: 96%;
  vertical-align: top;
  margin-top: 4%;
  margin-left: 4%;
}

#pageSizePanelPage11TextBox {        
  display: inline-block;
  width: 96%;
  vertical-align: top;
  margin-top: 3%;
  margin-left: 23.4%;
}

#pageSizePanelPage13TextBox {        
  display: inline-block;
  width: 96%;
  vertical-align: top;
  margin-top: 7%;
}

#pageSizePanelPage14TextBox {        
  display: inline-block;
  width: 96%;
  vertical-align: top;
  margin-top: 1%;
  margin-left: 5%;
}

#pageSizePanelPage15TextBox {        
  display: inline-block;
  width: 96%;
  vertical-align: top;
  margin-top: 1%;
  margin-left: 5%;
}


#pageSizePanelPage18TextBox {        
  display: inline-block;
  width: 96%;
  vertical-align: top;
  margin-top: 1%;
  margin-left: 5%;
}

#pageSizePanelRightTextBoxBottom {
  margin-top: 2.7%;
  width: 51%;
  text-align: left;
}

#pageSizePanelRightTextBoxBottom {

}

#pageSizePanelRightImageGridBottomRow {
  margin-bottom: 4.5%;
}

#pageSizePanelRightTextBoxTop p,
#pageSizePanelPage6TextBox p
#pageSizePanelPage9TextBox p,
#pageSizePanelPage10TextBox p,
#pageSizePanelPage11TextBox p,
#pageSizePanelPage13TextBox p,
#pageSizePanelPage14TextBox p,
#pageSizePanelPage15TextBox p,
#pageSizePanelPage16TextBox p,
#pageSizePanelPage17TextBox p,
#pageSizePanelPage18TextBox p {
  color: rgb(254, 236, 184);
  font-size: 1.05rem;
}

#action-force-description-top,
#unstableEarthDescription,
#darkPastDescription,
#calligraphyDescription,
#hyggeDescription,
#silburyHillDescription,
#wellsCathedralDescription,
.hurstCastleDescription,
#cookingSkillsDescription,
#pageSizePanelPage16TextBox {
  margin: 0;
  padding: 0;
  color: rgb(254, 236, 184);
  font-size: 1.05rem;
}

#action-force-description-bottom {
  margin: 0;
  padding: 0;
  color: rgb(254, 236, 184);
  font-size: 1.197rem;
  font-family: 'Libre Baskerville', serif;
}

#hurstCastleDescriptionQuote {
  margin: 0;
  padding: 0;
  color: rgb(254, 236, 184);
  font-size: 1.197rem;
  font-family: 'Libre Baskerville', serif;
  margin-top: 58%;
  width: 81%;
}

#hurstCastleDescriptionContainer {
  width: 50%;
  float: left;
}

#action-force-description-top strong,
#unstableEarthDescription strong,
#darkPastDescription strong,
#calligraphyDescription strong,
#hyggeDescription strong,
#silburyHillDescription strong,
#wellsCathedralDescription strong,
.hurstCastleDescription strong,
#pageSizePanelPage6TextBox strong,
#infoGraphicsDescription strong,
#pageSizePanelPage16TextBox strong { 
  color: rgb(254, 236, 184);
  font-size: 1.197rem;
  width: 100%;
}

.sliderDiv {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.sliderImage {
  height: 100px;
  width: 40px;
}

#page2Background,
#page19Background {
  width: 100%;
}

.ReactModalPortal {
  height: 100%;
}

#lighboxcontainer {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.whatHappensCoverContainer {
  height: 54%;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 23%;
}

#whatHappensCoverContainerLeftLeft,
#whatHappensCoverContainerLeftRight {
  display: inline-block;
  overflow: visible;
  width: 49%
}

#whatHappensCoverContainerLeftLeft {
  float: left;
}

#whatHappensCoverContainerLeftRight {
  float: right;
}

#darkPastContainerLeftRightTop,
#darkPastContainerLeftRightBottom,
#whatHappensCoverContainerLeftRightTop,
#whatHappensCoverContainerLeftRightBottom,
#darkPastContainerLeftRightBottom {
  line-height: 0;
  overflow: hidden;
  height: 48%;
}

#uEOzoneCover-slide-1 {
  margin-left: 11%;
  width: 88%;
  margin-top: 1%;
}

#uEOceansCover-slide-2,
#uEIceCapcover-slide-3,
#uERainforestsCover-slide-6 {
  margin-left: 1%;
  margin-top: 1%;
  width: 41.8%;
}

#uEOceansCover-slide-2,
#uEIceCapcover-slide-3 {
  margin-bottom: 4.3%;
}

#uEOceansCover-slide-2 {
  margin-right: 3%;
}

#uERainforestsCover-slide-6 {
  margin-bottom: 1%;
}

#ozone45-slide-4,
#oceans2425-slide-5,
#iceCaps2223-slide-7,
#iceCaps2425-slide-8 {
  margin-left: 0.5%;
  margin-top: 0.5%;
  width: 42.8%;
}

#ozone45-slide-4,
#iceCaps2223-slide-7{
  float: left;
  margin-right: 2%;
}

#whatHappensCoverContainerRightTopRow,
#whatHappensCoverContainerRightBottomRow {
  height: 50%;
  overflow: hidden;
  margin-bottom: 1.2%;
}

#whatHappensCoverContainerRight {
  margin-left: 6%;
}

#unstableEarthDescription {
  margin-top: 4%;
}

#darkPastDescription {
  margin-left: 8%;
  margin-top: 17%;
  margin-bottom: 2%;
}

#vikingCover61-slide-1 {
  width: 100%;
}

#darkPastContainerLeftRight,
#darkPastContainerRightLeft {
  width: 25%;
}

#darkPastContainerLeftLeft {
  float: left;
  margin-left: 12%;
  width: 56%;
}

#darkPastContainerLeftRight {
  float:right;
  margin-right: 4%;
  margin-top: -1%;
  overflow: visible;
}

#darkPastContainerRightLeft {
  float: left;
  margin-left: 4%;
  margin-top: 17.7%;
}

#ghostsCover63-slide-2,
#aliensCover6-slide-5,
#vikingCover66-slide-3,
#witchesCover64-slide-6 {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 4%;
  margin-bottom: 7%;
  width: 95%;
}

#darkPastContainerRightRight {
  margin-top: 35.4%;
  float: right;
  width: 46%;
  margin-right: 22%;
}

#vikingsSpread623-slide-4 {
  width: 100%;
}

#pageSizePanelLeftPage9ImagesContainer {
  margin-left: 4%;
  /*margin-top: 10%;*/
  margin-top: 10vh;
  width: 92%;
}

#calligraphy100101-slide-1,
#calligraphy6263-slide-2,
#calligraphy2021-slide-4 {
  width: 49%;
}

#calligraphy100101-slide-1 {
  margin-right: 2%;
}

#calligraphy2021-slide-4 {
  margin-top: 9.1%;
}

#calligraphy4041-slide-3 {    
  margin-left: 4%;
  /*margin-top: 17%;*/
  margin-top: 17vh;
  width: 93%;
}

#hygge2627Container {    
  width: 96%;
  overflow: hidden;
  float: right;
  margin-top: 8%;
  /* margin-right: 1%; */
}

#hygge2627-slide-1 {
  margin-left: 1%;
  margin-top: 1%;
  /* margin-right: 1%; */
  margin-bottom: 1%;
  /* padding-right: 1%; */
  width: 97%;
}

#hygge5253hygge1011Container {
  width: 49%;
  overflow: visible;
  margin-left: 4%;
  height: 65%;
  margin-top: 16%;
}

#hygge5253hygge1011ContainerTop {
  margin-bottom: 2%;
}

#hygge5253-slide-2,
#hygge1011-slide-3 {
  margin: 1%;
  width: 98%;
}

#pageSizePanelLeftPage14ImageContainer {
  width: 86%;
  overflow: hidden;
  margin-left: 5%;
  /*margin-top: 33%;*/
  margin-top: 33vh;
}

#pageSizePanelLeftPage14ImageContainerLeft,
#pageSizePanelLeftPage14ImageContainerRight {    
  width: 49%;
  overflow: hidden;
  padding: 0.5%;
}

#pageSizePanelLeftPage14ImageContainerLeft {
  float: left;
}

#pageSizePanelLeftPage14ImageContainerRight {
  float: right;
}

#wells1415Image-slide-1,
#wells1011Image-slide-2 {
  width: 100%;
}

#pageSizePanelRightPage14ImageContainer {
  width: 88%;
  overflow: hidden;
  /*margin-top: 33%;*/
  margin-top: 33vh;
  float: right;
  margin-right: 8%;
}

#pageSizePanelRightPage14ImageContainerLeft,
#pageSizePanelRightPage14ImageContainerRight {
  width: 23.96%;
  /* margin: 1%; */
  padding: 0.8%;
  overflow: hidden;
}

#pageSizePanelRightPage14ImageContainerCentre{
  width: 47.9%;
  overflow: hidden;
  margin: 0;
  float: left;
  padding: 0.8%;
  padding-right: 0;
}

#pageSizePanelRightPage14ImageContainerLeft {
  float: left;
}

#pageSizePanelRightPage14ImageContainerRight {
  float: right;
}

#wells1415Image-slide-1,
#wells1011Image-slide-2,
#wellsCoverImage-slide-3,
#wellsPage12Image-slide-4,
#page30Image-slide-5 {
  /* margin: 1%; */
  width: 98%;
  /* margin-top: 2%; */
}



#pageSizePanelLeftPage15TopRow {
  margin-top: 8vh;
  /*overflow: hidden;*/
  height: 40.9%;
  width: 84%;
  margin-left: 7%;
  /* padding: 0.5%; */
  padding-top: 0.2%;
  padding-right: 1.5%;
  /* margin-right: 1%; */
  display: block;
}

#pageSizePanelLeftPage15BottomRow {
  /* overflow: hidden; */
  height: 40.9%;
  width: 84.6%;
  margin-left: 7%;
  margin-top: 1vh;
  /* padding-right: 0.5%; */
  display: block;
}

#coverV6ImageContainer {
  float: left;
  width: 27%;
}

#coverV6Image-slide-1 {
  width: 100%;
}

#hurstCastle23ImageContainer {
  float: right;
  width: 56%;
}

#hurstCastle23Image-slide-2 {
  width: 100%;
}

#hurstCastle1415ImageContainer {
  float: right;
  width: 55.6%;
  /* margin-left: 43.7%; */
  margin-right: 0.6%;
  margin-top: 1%;
}

#hurstCastle1415Image-slide-4 {
  width: 100%;
}

#pageSizePanelPage15TextBox {
  margin-left: 0;
  margin-top: 2.5%;
  width: 41%;
  float: left;
}

#pageSizePanelPage18TextBox {
  margin-left: 4%;
  margin-top: 3%;
  width: 41%;
  float: left;
}

#hurstCastle1617ImageContainer {
  margin-top: 8vh;
  float: right;
  margin-right: 7%;
  width: 89%;
}

#hurstCastle1617Image-slide-3 {
  width: 100%;
}

#coverV6Image-slide-1,
#hurstCastle23Image-slide-2,
#hurstCastle1415Image-slide-4,
#hurstCastle1617Image-slide-3 {
  margin: 1%;
}

#silburyHillBook188FinalImageContainer {
  margin-top: 14vh;
  margin-left: 23.4%;
  width: 72%;
}

#silburyHillBook188FinalImage-slide-1 {
  width: 100%;
}

#silburyHillBook88200FinalImageContainer {
  float: left;
  margin-top: 14vh;
  margin-left: 3%;
  width: 72%;
}

#silburyHillBook88200FinalImage-slide-2 {
  width: 100%;
}

#silburyHillCoverFinalImageContainer {
  float: right;
  width: 37%;
  margin-right: 4%;
  margin-top: -33%;
}

#silburyHillCoverFinalImage-slide-3 {
  width: 100%;
}

#seriesCoverDesigns2ImageContainer {
  width: 100%;
}

#pageSizePanelLeftPage13ImageAndTextContainer {
  margin-left: 4%;
  margin-top: 8vh;
  width: 42%;
  float: left;
}

#seriesCoverDesigns2Image-slide-1 {
  width: 100%;
}

#seriesCoverDesigns4ImageContainer {
  float: right;
  width: 43%;
  margin-top: 32vh;
  margin-right: 7%;
}

#seriesCoverDesigns3Image-slide-2,
#seriesCoverDesigns1Image-slide-3,
#seriesCoverDesigns6Image-slide-5,
#seriesCoverDesigns4aImage-slide-6 {
  margin-right: 3%;
  width: 47%;
}

#seriesCoverDesigns3Image-slide-2,
#seriesCoverDesigns1Image-slide-3 {
  margin-bottom: 2.1%;
}

#navigatorGuidesSampleSpread1213ImageContainer {
  float: right;
  margin-right: 13%;
  width: 83%;
  margin-top: 8vh;
}

#navigatorGuidesSampleSpread1213Image-slide-4 {
  width: 100%;
}

#navigatorGuidesSampleSpread1415ImageContainer {
  width: 41%;
  float: right;
  margin-right: 55%;
  margin-top: 1.5%;
}

#navigatorGuidesSampleSpread1415Image-slide-7 {
  width: 100%;
}

#pageSizePanelLeftpage6TopLeftImagesContainer {
  margin-top: 8%;
  margin-left: 4%;
  width: 92%;
}

#csPartyFood17Image-slide-1,
#csPartyFood3233Image-slide-2,
#csHealthySnacks3637Image-slide-4,
#csClassics4041Image-slide-5 {
  width: 44%;
}

#csPartyFood3839ImageContainer {
  float: right;
  margin-top: 8%;
  margin-right: 4%;
  width: 92%;
}

#csPartyFood3839Image-slide-3 {
  width: 100%;
}

#pageSizePanelLeftpage6BottomLeftImagesContainer {
  margin-left: -108.7%;
  width: 200%;
  margin-top: -2%;
}

#pageSizePanelLeftpage6BottomLeftImagesContainerLeft {
  width: 22%;
  float: left;
}

#pageSizePanelLeftpage6BottomLeftImagesContainerRight {
  width: 76%;
  float: right;
}

#csDrinksAndDeserts1213Image-slide-6 {
  width: 100%;
}

#classicCoverImage-slide-7,
#drinksCoverImage-slide-8,
#healthyCoverImage-slide-9,
#partyCoverImage-slide-10,
#pastaCoverImage-slide-11,
#veggieCoverImage-slide-12 {
  width: 14.45%;
  margin-right: 0.7%;
}

#pageSizePanelRightpage6 {
  z-index: -1;
  overflow: visible;
}

#pageSizePanelLeftpage6BottomLeftImagesContainerRight {
  z-index: 10;
  position: relative;
  margin-right: 1%;
}

#pageSizePanelLeftpage6 {
  overflow: visible;
}

#csPartyFood17Image-slide-1,
#csHealthySnacks3637Image-slide-4 {
  margin-right: 12%;
}

#csPartyFood17Image-slide-1,
#csPartyFood3233Image-slide-2 {
  margin-bottom: 3%;
}

#infoGraphicsImagesLeftContainer {
  margin-left: 4%;
  width: 91%;
  margin-top: 33vh;
}

#infoGraphicsImagesRightContainer {
  float: right;
  width: 88%;
  margin-top: 33vh;
}

#infoGraphicsImagesLeftContainerLeft,
#infoGraphicsImagesLeftContainerCentre,
#infoGraphicsImagesLeftContainerRight,
#infoGraphicsImagesRightContainerLeft,
#infoGraphicsImagesRightContainerCentre,
#infoGraphicsImagesRightContainerRight {
  display: inline-block;
}

#infoGraphicsImagesLeftContainerLeft {
  width: 35%;
  margin-right: 4%;
  float: left;
}

#infoGraphicsImagesLeftContainerCentre {
  width: 32%;
  margin-right: 4%;
}

#infoGraphicsImagesLeftContainerRight {
  width: 25%;
  float: right;
}

#infoGraphicsImagesRightContainerLeft {
  width: 25%;
  margin-right: 4%;
  float: left;
}

#infoGraphicsImagesRightContainerCentre {
  width: 33%;
  margin-right: 4%;
}

#infoGraphicsImagesRightContainerRight {
  width: 32%;
  float: right;
}

#infoGraphicsImagesRightContainer {
  margin-right: 7%;
}

#infoGraphics1Image-slide-1,
#infoGraphics2Image-slide-2,
#infoGraphics3Image-slide-3,
#infoGraphics4Image-slide-4,
#infoGraphics5Image-slide-5,
#infoGraphics6Image-slide-6 {
  width: 100%;
}

#pageSizePanelPage16TextBox {
  float: left;
  /* height: 100%; */
  width: 26%;
  margin-top: 46%;
}

#pageSizePanelLeftPage16ImageRowTop {
  float: right;
  width: 71%;
  margin-right: 3%;
  margin-top: 8vh;
}

#pageSizePanelLeftPage16ImageRowBottom {
  float: right;
  width: 71%;
  margin-right: 3%;
  margin-top: 1.5%;
}

#clovellyGuideBookRecipeBook1ImageContainer,
#clovellyGuideBookRecipeBook4ImageContainer {
  float: left;
  width: 32%
}

#clovellyGuideBookRecipeBook2ImageContainer,
#clovellyGuideBookRecipeBook5ImageContainer {
  float: right;
  width: 64%;
}

#clovellyGuideBookRecipeBook1Image-slide-1,
#clovellyGuideBookRecipeBook2Image-slide-2,
#clovellyGuideBookRecipeBook4Image-slide-4,
#clovellyGuideBookRecipeBook5Image-slide-5 {
  width: 100%;
}

#clovellyGuideBookRecipeBook3ImageContainer {
  margin-right: 7%;
  float: right;
  width: 85%;
  margin-top: 8vh;
}

#clovellyGuideBookRecipeBook3Image-slide-3 {
  width: 100%;
}

#pageSizePanelPage16TextBoxTop {
  margin: 0 auto;
  width: 52%;
}

#infoGraphicsDescriptionTop,
#pageSizePanelPage17TextBoxTop {
  margin: 0 auto;
}

#pageSizePanelPage17TextBoxTop {
  width: 100%;
}

#clovellyVillageNewsletterTextBottom {
  width: 102%;
}

#infoGraphicsDescriptionMid {
  width: 74%;
  margin-left: 24%;
}

#pageSizePanelPage17TextBox {
  margin-top: 60%;
}

#infoGraphicsDescriptionBottom {
  margin: 0 auto;
  margin-top: 3vh;
  width: 52%;
}

#clovellyVillageNewsletterTextMid {
  margin-top: 3.9vh;
  margin-bottom: 0.5vh;
}

#clovellyVillageNewsletterTextBottom {
  margin-top: 0.2vh;
}

#clovellyLeafletsNewsletterRightTopContainer {
  overflow: hidden;
  height: 50%;
  display: block;
}

#clovellyLeafletsNewsletterRightBottomContainer {
  /*overflow: hidden;*/
  height: 50%;
  display: block;
}

#clovellyLeafletsNewsletterLeftBottomContainer{
  overflow: visible;
  height: 50%;
  display: block;
}

#clovellyLeafletsNewsletterLeftTopContainer {
  overflow: visible;
  height: 50%;
  display: block;
  float: left;
  width: 100%;
}

#clovellyLeafletsNewsletterImage2Container {
  width: 70%;
  overflow: hidden;
  float: right;
  margin-right: 4%;
  margin-top: 8%;
}

#clovellyLeafletsNewsletterImage2-slide-2 {    
  width: 100%;
  height: 100%;
}

#clovellyLeafletsNewsletterImage67Container {
  margin-left: 4%;
  width: 69%;
}

#clovellyLeafletsNewsletterImage6Container,
#clovellyLeafletsNewsletterImage7Container {
  width: 44%;
}

#clovellyLeafletsNewsletterImage6Container {
  float: left;
}

#clovellyLeafletsNewsletterImage7Container {
  float: right;
}

#clovellyLeafletsNewsletterImage5-slide-5 {
  width: 100%;
}

#clovellyLeafletsNewsletterImage6-slide-6 {
  width: 100%;
}

#clovellyLeafletsNewsletterImage7-slide-7 {
  width: 100%;
}

#clovellyLeafletsNewsletterImage34Container {
  margin-top: 8%;
  margin-left: 4%;
  overflow: hidden;
  width: 73%;
  height: 81%;
}

#clovellyLeafletsNewsletterImage4Container {
  float: right;
  width: 35%;
  margin-top: -56%;
  position: relative;
}

#clovellyLeafletsNewsletterImage4-slide-4 {
  width: 100%;
  position: relative;
  margin-left: -20%;
  margin-top: -15%;
}

#clovellyLeafletsNewsletterImage3Container {
  width: 70%;
  margin-top: 8%;
  overflow: hidden;
  height: 90%;
}

#clovellyLeafletsNewsletterImage3-slide-3 {
  width: 100%;
}

#clovellyLeafletsNewsletterLeftBottomContainer {
  width: 100%;
  /*background-color: black;*/
}

#clovellyLeafletsNewsletterLeftBottomContainerLeft {
  /* background-color: brown; */
  width: 27%;
  float: left;
  /* overflow: hidden; */
  height: 100%;
}

#clovellyLeafletsNewsletterLeftBottomContainerRight {
  /*background-color: lightslategray;*/
  width: 43%;
  float: right;
  /*overflow: hidden;*/
  height: 100%;    
  margin-top: 0;
  margin-bottom: 3%;
}

#clovellyLeafletsNewsletterRightBottomContainerLeft {
  /*background-color: lightpink;*/
  width: 44%;
  float: left;
  /*overflow: hidden;*/
  height: 100%;
}

#clovellyLeafletsNewsletterRightBottomContainerRight {
  /*background-color: lightyellow;*/
  width: 44%;
  float: right;
  /*overflow: hidden;*/
  height: 100%;
}

#clovellyLeafletsNewsletterRightTopContainerBothImages {
  /*background-color: turquoise;*/
  width: 78%;
  height: 91.5%;
  margin-left: 4%;
  margin-top: 8.5%;
}

#clovellyLeafletsNewsletterLeftTopContainerBothImages {
  width: 88%;
  /*background-color: palegoldenrod;*/
  height: 71.5%;
  float: right;
  margin-right: 4%;
  margin-top: 8.5%;
}

#clovellyLeafletsNewsletterRightTopContainerLeftImage {
  width: 67%;
  /*height: 100%;*/
  float: left;
  /*background-color: cyan;*/
}

#clovellyLeafletsNewsletterRightTopContainerRightImage {
  width: 33%;
  /*height: 100%;*/
  float: right;
  /*background-color: darkkhaki;*/
}

#clovellyLeafletsNewsletterLeftTopContainerRightImage {
  height: 100%;
  float: right;
  /*background-color: mediumslateblue;*/
  width: 76%;
}

#clovellyLeafletsNewsletterRightBottomContainerBothImages {
  width: 69%;
  height: 100%;
  /*background-color: rgb(40, 31, 31);*/
  margin-left: 4%;
}

#clovellyLeafletsNewsletterLeftBottomContainerBothImages {
  width: 70%;
  margin-right: 4%;
  height: 100%;
  float: right;
}

#clovellyLeafletsNewsletterLeftTopContainerLeftImage {
  height: 100%;
  width: 24%;
  float: left;
}

#clovellyLeafletsNewsletterImage1-slide-1 {
  height: 100%;
  position: relative;
  margin-top: 62%;
  transform: rotate(-5deg);
  margin-left: 32%;
}

#pageSizePanelLeftRightContainerPage1 {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

#pageSizePanelLeftRightContainerPage2,
#page3,
#page8,
#page12,
#pageSizePanelLeftRightContainerPage19 {
  /* Full height */
  height: 100%; 
  transform: translateZ(0);
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#page3 h1,
#page8 h1,
#page12 h1 {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 2.05rem;
  color: rgb(254, 236, 184);
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  font-weight: lighter;
  /* margin-top: 0%; */
  padding-top: 21vh;
  padding-left: 4.5vw;
  /* vertical-align: bottom; */
}

#panelContentContainerTextContainer {
  margin: 0;
}

#sbLogo1 {
  position: relative;
  z-index: 5;
  /* margin-top: -100%; */
  /* margin-top: 21vh; */
  display: block;
  margin: 0 auto;
  /* min-width: 26vw; */
  /* min-width: 100%; */
  max-height: 50vh;
  cursor: default;
  max-width: 75vw;
}

#sbLogo2 {
  position: relative;
  z-index: 5;
  /* margin-top: -100%; */
  /* margin-top: 21vh; */
  display: block;
  margin: 0 auto;
  min-width: 26vw;
  /* min-width: 100%; */
  max-height: 50vh;
  cursor: default;
  max-width: 75vw;
}

#sbLogo2Container {
  margin-top: 23vh;
  width: 100%;
}

#logoPage1Box {
  margin-left: 37%;
  width: 485px;
  margin-right: 37%;
  height: 485px;
  position: relative;
  z-index: 5;
  /* margin-top: -100%; */
  background-color: rgb(0, 65, 74);
  margin-top: 13%;
  border: 5px solid rgb(184,	177,	111)
}

#logoPage2Box {
  margin: 0;
  margin-left: 28%;
  width: 54%;
  /* margin-right: 37%; */
  /* height: 57vh; */
  z-index: -1;
  /* margin-top: -100%; */
  background-color: rgb(0, 65, 74);
  border: 4px solid rgb(184,	177,	111);
  /*outline: 6px solid rgb(35, 31, 32);*/
  outline: 6.5px solid rgb(0, 0, 0);
  margin-top: 19vh;
  overflow: hidden;
}



#logoPage2BoxInner {
  border: 2.25px solid rgb(0, 0, 0);
  height: 100%;
  overflow: hidden;
}


#page1Background {
  position: relative;
  z-index: -1;
}

#page2 {
  overflow: hidden;
}

#logoPage2Box h1 {
  margin: 0;
  vertical-align: bottom;
}

#pageSizePanelLeftRightContainerPage2 {
  overflow: hidden;
}

#logoPage1Box h1,
#logoPage1Box h2 {
  font-family: 'Cinzel', serif;
  font-weight: 100;
  color: rgb(254, 236, 184);
}

#aboutMeContainerLeft {
  float: left;
  width: 35.7%;
}

#aboutMeContainerLeft h1 {
  color: rgb(254, 236, 184);
  font-family: 'Libre Baskerville', serif;
  font-weight: lighter;
  font-size: 2.75em;
  margin-top: 15.5vh;
  margin-left: 25%;
}

#aboutMeFontContainer {
  margin-top: 15.5vh;
  /*margin-left: 25%;*/
}

#aboutMeFont {
  float: none;
  margin: 0 auto;
  width: 11.2em;
  margin-left: 25%;
  cursor: default;
}

#aboutMeImageContainer {
  margin: 0 auto;
  width: 100%;
  margin-top: 3.5%;
}

#aboutMeImage {
  float: none;
  margin: 0 auto;
  width: 11em;
  margin-left: 25%;
  border: 1.5px solid rgb(153, 36, 44);
  cursor: default;
}

#aboutMeContainerRight {
  width: 58.3%;
  margin-right: 6%;
  float: right;
  color: rgb(255, 245, 222);
  margin-top: 4%;
  font-size: 1.1em;
  margin-bottom: 4.25vh;
}

#sbLogo1ContainerRow {
  margin-top: 28vh;
  width: 100%;
}

#mainContentAllContainer {
  background-color: rgb(0, 54, 59);
}










#navBarScrollableAnchorContainer {
  width: 100%;
  position: fixed;
  height: 3.7em;
  z-index: 999;
  background-color: rgba(0,0,0, 0.55);
}

#navBarScrollableAnchor a {
  color: rgb(254, 237, 184);
  font-family: 'PT Sans', sans-serif;
  text-decoration: none;
  font-size: 1.06em;
}

#navBarScrollableAnchor {
  margin-right: 2%;
  height: 100%;
}

#navBarScrollableAnchorLeft {
  float: left;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  width: 20%;
}

#navBarScrollableAnchorRight {
  float: right;
  display: inline-block;
  height: 100%;
}

#navBarScrollableAnchorLeft a {
  margin-left: 13%;
}

#navLogo {
  width: 2.8em;
  margin-top: 0.4rem;
}

#navBarScrollableAnchorContainer {
  transition: top 0.3s
}

#navBarScrollableAnchorRightNavLinksContainer {
    margin-top: 1.7em;
    float: right;
}




#navBarScrollableAnchorRightNavLinksContainer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#navBarScrollableAnchorRightNavLinksContainer li {
  float: left;
}

#navBarScrollableAnchorRightNavLinksContainer a {
  display: block;
  padding: 0 1.1em;
}

#navIconContainer {
  height: 3.7em;
  overflow: hidden;
  margin-right: 0.8em;  
}
















.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1999;
}
.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}
.headroom--scrolled {
  transition: transform 0.3s ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}














#narrowNavDropDown {
  transition: 0.4s ease-in-out;
  margin: 0;
  opacity: 0;
  z-index: -10000;
  background: rgba(0,0,0, 0.0);
  height: 0;
  overflow: hidden;
}

#narrowNavDropDown.open {
  margin-top: -0.02em;
  opacity: 1;
  z-index: 999;
  background: rgba(0,0,0, 0.55);
  overflow: hidden;
  height: 13.02em;
}

#navBarScrollableAnchorRightNavLinksContainerNarrow ul {
  list-style-type: none;
}

#narrowNavDropDown li a {
  float: right;
  margin-right: 0.81rem;
  margin-top: 0.4rem;
  margin-bottom: 0.3rem;
}

#narrowNavDropDown.open li a {
  float: right;
  margin-right: 0.81rem;
  margin-top: 0.4rem;
  margin-bottom: 0.3rem;
  font-size: 1.06em;
}

#pageSizePanelLeftPage7,
#darkPastContainerRightLeft,
#pageSizePanelRightPage7,
#pageSizePanel-page7 {
  overflow: visible;
}









#pageSizePanelRightImageGrid,
.whatHappensCoverContainer,
#whatHappensCoverContainerLeftRightTop,
#whatHappensCoverContainerLeftRightBottom,
#whatHappensCoverContainerRightTopRow,
#whatHappensCoverContainerRightBottomRow,
#darkPastContainerLeftRightTop,
#darkPastContainerLeftRightBottom,
#pageSizePanelRightPage14ImageContainerLeft, 
#pageSizePanelRightPage14ImageContainerRight,
#pageSizePanelRightPage14ImageContainer,
#pageSizePanelLeftPage14ImageContainerLeft, 
#pageSizePanelLeftPage14ImageContainerRight,
#pageSizePanelLeftPage14ImageContainer,
#pageSizePanelRightPage14ImageContainerCentre {
  overflow: visible;
}


#pageSizePanel-page2,
#page2,
pageSizePanel-page13 {
  min-height: fit-content;
}

#page13 {
  height: fit-content;
}

#page2 {
  height: 100%;
}

#logoPage2Box {
  margin-bottom: 7%;
}


















/*-- Background Images --*/

#pageSizePanelLeftRightContainerPage1PreLoad {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgb(57, 71, 76);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: -1;
}

#pageSizePanelLeftRightContainerPage1 {
  background-color: rgb(57, 71, 76);
  min-height: 100%;
  position: relative;
  overflow: hidden;
  transition: 0.6s ease-in-out;
}

#pageSizePanelLeftRightContainerPage1 ::before {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: -1;
}

#pageSizePanelLeftRightContainerPage2,
#pageSizePanelLeftRightContainerPage19 {
  background-color: rgb(0, 41, 44);
}

#pageSizePanelLeftRightContainerPage19 {
  overflow: hidden;
}

#page3 {
  background-color: rgb(56, 65, 53);
}

#page8 {
  background-color: rgb(208, 143, 28);
}

#page12{
  background-color: rgb(106, 113, 81);	
}












#invisPageH1 {
  color: white;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
}







#sbLogo2ContainerWithLink {
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
}

#sbLogo2 {
  cursor: pointer;
}























/* Icon 4 */

#nav-icon4 {
  width: 1.9em;
  height: 1.9em;
  position: relative;
  margin: 0.5rem 0 0.41rem auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-top: 1em;
}

#nav-icon4 span {    
  display: block;
  position: absolute;
  height: 11.9%;
  width: 100%;
  background: rgb(254, 236, 184);
  border-radius: 8px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
}

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 44%;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 86%;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) { 
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -2%;
  width: 136%;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 92%;
  width: 136%;
}

#booksGuide,
#booksAdults,
#booksChildren,
#booksGuide {
  background-color: rgb(0, 54, 59);
}

#creditTextContainer {
  vertical-align: bottom;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 100%;
}

#creditText {
  color: rgb(254, 237, 184);
  float: right;
  margin-right: 3%;
  font-weight: lighter;
  vertical-align: bottom;
  text-align: center;
}

#errorTextContainer {
  margin: 0 auto;
  width: fit-content;
  margin-top: 25vh;
}

#homeError {
  height: 100vh;
  position: fixed;
  width: 100vw;
  background-color: rgb(0, 54, 59);
}

#errorLine1,
#errorLine2 {
  color: white;
  margin: 0 auto;
}




































































































































@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
  z-index: 999;
  cursor: default;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
  z-index: 1005;
}
.ril__navButtons:hover {
  opacity: 1;
}
.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
    no-repeat center;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
    no-repeat center;
  z-index: 999;
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
  background-color: rgba(0,0,0, 0.55);
  z-index: 1101;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  flex: 0 0 auto;
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}
.ril__builtinButton:hover {
  opacity: 1;
}
.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
    no-repeat center;
}

.ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__outerAnimating {
  animation-name: closeWindow;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

button {
  color: rgb(254, 236, 184);
}



#creditEmailLink,
#creditEmailLink a {
  color: inherit;
  text-decoration: none;
}




























































































































































@media (max-width: 950px) {
  #pageSizePanelLeftClickToEnlargeContainer,
  #pageSizePanelRightImageGrid,
  #pageSizePanelRightTextBoxTop, 
  #pageSizePanelRightTextBoxBottom {
      width: 85%;
  }

  #action-force-SpecialForces20-21-container {
      width: 84%;
  }

  #pageSizePanelRightTextBoxTop,
  #pageSizePanelRightTextBoxBottom {
      margin-left: 7.8%;
  }
}

@media (max-width: 1400px) {
  .pageSizePanelLeft,
  .pageSizePanelRight {
      float: none;
      width: 100%;
      height: 50%;
  }

  #clovellyLeafletsNewsletterImage1-slide-1 {
    width: 100%;
  }

  #pageSizePanelLeftClickToEnlargeContainer,
  #pageSizePanelRightImageGrid,
  #pageSizePanelRightTextBoxTop, 
  #pageSizePanelRightTextBoxBottom {
      width: 76.5%;
  }


  #pageSizePanelLeftClickToEnlargeContainer {
      margin: 0 auto;
      margin-top: 7%;
  }

  #pageSizePanelRightImageGrid {
      margin: 0 auto;
      margin-top: 0.5%;
      float: none;
  }

  #pageSizePanel-page4,
  #pageSizePanel-page5,
  #pageSizePanel-page6,
  #pageSizePanel-page7,
  #pageSizePanel-page9,
  #pageSizePanel-page10,
  #pageSizePanel-page11,
  #pageSizePanel-page13,
  #pageSizePanel-page14,
  #pageSizePanel-page15,
  #pageSizePanel-page16,
  #pageSizePanel-page17,
  #pageSizePanel-page18   {
      min-height: 100vh;
      height: auto;
  }

  #action-force-SpecialForces20-21-container {
      margin-bottom: 3.5%;
      width: 75%;
  }

  #pageSizePanelRightTextBoxTop, 
  #pageSizePanelRightTextBoxBottom {
      display: inline-block;
      margin: 0 auto;
      margin-left: 11.9%;
      float: none;
  }

  #pageSizePanelRightTextBoxBottom {
    margin-bottom: 7%;
  }

  #action-force-description-top {
      margin-bottom: 1.5%;
  }

  #unstableEarthDescription {
      margin-bottom: 3.5%;
  }

  #unstableEarthDescription {
    margin-top: 4%;
  }

  .whatHappensCoverContainer {
    margin: 0 auto;
    margin-top: 3%;
    width: 76.5%;
  }

  #ozone45-slide-4, #oceans2425-slide-5, #iceCaps2223-slide-7, #iceCaps2425-slide-8 {
    width: 48.5%;
  }

  #uEOzoneCover-slide-1 {
    margin-left: 1%;
    width: 97%;
  }

  #whatHappensCoverContainerLeftRight {
    margin-left: 2%;
  }

  #uEOceansCover-slide-2, #uEIceCapcover-slide-3, #uERainforestsCover-slide-6 {
    width: 46.6%;
  }

  #whatHappensCoverContainerLeftLeft, #whatHappensCoverContainerLeftRight{
    width: 49%;
  }

  #whatHappensCoverContainerRight {
    margin: 0 auto;
    margin-top: 1.5%;
    margin-bottom: 7%;
  }

  #darkPastDescription {
    margin-left: 0%;
    margin-top: 8%;
  }

  #darkPastContainerLeftLeft {
    margin-left: 0%;
    width: 62%;
  }

  #darkPastContainerLeft {
    width: 76.5%;
    margin: 0 auto;
  }

  #pageSizePanelRightTextBoxTop {
    width: 76.5%;
    float: none;
  }

  #darkPastContainerLeftRight, #darkPastContainerRightLeft {
    margin-top: 3.5%;
    width: 31.2%;
  }

  #darkPastContainerLeftRight {
    margin-right: 1%;
    margin-top: -1%;
  }

  #pageSizePanelLeftPage7 {
    margin-bottom: 1.5%;
  }

  #darkPastContainerRight {
    width: 76.5%;
    margin: 0 auto;
  }

  #darkPastContainerRightRight {    
    margin-right: 2%;
    margin-top: 21.4%;
    width: 64%;
  }
  
  #pageSizePanelRightPage7 {    
    margin-bottom: 7%;
    margin-top: -2%;
  }

  #darkPastContainerRightLeft {
    margin-left: 0;
  }

  #vikingCover66-slide-3, #witchesCover64-slide-6 {
    margin-left: 1%;
  }

  #pageSizePanelLeftpage6TopLeftImagesContainer,
  #csPartyFood3839ImageContainer {
    margin: 0 auto;
    width: 76.5%;
    float: none;
  }

  #pageSizePanelLeftpage6TopLeftImagesContainer {
    margin-top: 7%;
  }

  #csPartyFood3839ImageContainer {
    margin-top: 2.8%;
  }

  #pageSizePanelLeftPage9ImagesContainer {
    margin: 0 auto;
    width: 76.5%;
    margin-top: 7%;
    margin-bottom: 1.5%;
  }

  #calligraphy2021-slide-4 {
    margin-top: 3%;
  }

  #calligraphy4041-slide-3 {
    margin: 0 auto;
    width: 100%;
  }

  #calligraphy4041Container {
    margin: 0 auto;
    width: 76.5%;
    margin-top: 1.5%;
    margin-bottom: 7%;
  }

  #pageSizePanelRightPage9 {
    margin-bottom: 0;
  }

  #whatHappensCoverContainerLeft {
    margin-top: 7%;
  }

  #hygge2627Container {
    margin: 0 auto;
    float: none;
    margin-top: 7%;
    width: 76.5%;
  }

  #pageSizePanelPage10TextBox {
    margin: 0 auto;
    width: 76.5%;
    margin-bottom: 1.5%;
    margin-top: 3%;
    float: none;
    display: block;
  }

  #pageSizePanelPage11TextBox {
    margin: 0 auto;
    width: 76.5%;
    margin-bottom: 1.5%;
    margin-top: 3%;
    float: none;
    display: block;
  }

  #pageSizePanelPage13TextBox {
    margin: 0 auto;
    width: 76.5%;
    margin-bottom: 1.5%;
    margin-top: 3%;
    float: none;
    display: block;
    margin-left: 0.5%;
  }

  #pageSizePanelPage14TextBox {
    margin: 0 auto;
    width: 76.5%;
    margin-bottom: 1.5%;
    float: none;
    display: block;
  }

  #pageSizePanelPage15TextBox {
    margin: 0 auto;
    width: 76.5%;
    margin-bottom: 1.5%;
    float: none;
    display: block;
  }

  #pageSizePanelPage18TextBox {
    margin: 0 auto;
    width: 76.5%;
    margin-bottom: 1.5%;
    float: none;
    display: block;
  }

  #hygge5253hygge1011Container {
    display: block;
    margin: 0 auto;
    width: 76.5%;
    margin-top: 1.5%;
    float: none;
    margin-bottom: 7%;
  }

  #hygge5253hygge1011ContainerTop, #hygge5253hygge1011ContainerBottom {
    margin-bottom: 0;
    width: 49%;
  }

  #hygge5253hygge1011ContainerTop {
    float: left;
  }

  #hygge5253hygge1011ContainerBottom {
    width: 47.8%;
    float: right;
  }

  #pageSizePanelLeftPage14ImageContainer,
  #pageSizePanelRightPage14ImageContainer {
    display: block;
    margin: 0 auto;
    width: 76.5%;
  }

  #pageSizePanelLeftPage14ImageContainer {
    overflow: hidden;
    margin-top: 7%;
  }

  #wellsCathedralDescription {    
    margin-top: 1.5%;
    margin-bottom: 0;
  }
  
  .hurstCastleDescription {
    margin-top: 1.5%;
    margin-bottom: 0;
  }

  #pageSizePanelRightPage14ImageContainer {
    width: 76.5%;
    margin: 0 auto;
    margin-top: 1.5%;
    float: none;
  }

  #pageSizePanelLeftPage14ImageContainerRight {
    padding-right: 0;
  }

  #pageSizePanelPage15TextBox {
    margin-left: 0;
    width: 40%;
    float: left;
  }

  #pageSizePanelPage18TextBox {
    margin-left: 0;
    width: 40%;
    float: left;
  }

  #pageSizePanelLeftPage15TopRow,
  #pageSizePanelLeftPage15BottomRow {
    margin: 0 auto;
    width: 76.5%;
  }

  #pageSizePanelLeftPage15TopRow {
    margin-top: 7%;
    overflow: hidden;
  }

  #pageSizePanelLeftPage15BottomRow {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
  }

  #hurstCastle1617ImageContainer {
    float: none;
    margin: 0 auto;
    margin-top: 1.5%;
    margin-bottom: 7%;
    width: 76.5%;
  }

  #hurstCastle1415ImageContainer {
    margin-right: 1%;
  }

  #hurstCastle1617Image-slide-3 {
    margin-left: 0;
  }

  #pageSizePanelLeftPage11,
  #pageSizePanelRightPage11 {
    margin: 0 auto;
    width: 76.5%;
  }

  #silburyHillBook188FinalImageContainer {
    margin: 0 auto;
    margin-top: 7%;
  }

  #silburyHillBook88200FinalImageContainer {
    margin-top: 3.5%;
  }

  #silburyHillCoverFinalImage-slide-3 {
    margin-bottom: 28%;
  }

  #pageSizePanelLeftPage13,
  #pageSizePanelRightPage13 {
    width: 76.5%;
    margin: 0 auto;
  }

  #seriesCoverDesigns4ImageContainer {
    margin-bottom: 1.5%;
    margin-top: 23%;
    margin-right: -0.5%;
    width: 49%;
  }

  #pageSizePanelLeftPage13ImageAndTextContainer {
    margin-left: 0.8%;
    width: 46%;
  }

  #navigatorGuidesSampleSpread1213ImageContainer {
    margin: 0 auto;
    margin-top: 1.5%;
    float: none;
    width: 98.5%;
  }

  #navigatorGuidesSampleSpread1415ImageContainer {
    margin: 0 auto;
    float: left;
    margin-bottom: 7%;
    margin-top: 1.5%;
    width: 70%;
    margin-left: 0.8%;
  }

  #pageSizePanelLeftpage6BottomLeftImagesContainer {
    height: 10%;
    /* overflow: hidden; */
    width: 100%;
    margin: 0 auto;
    margin-top: 3.5%;
  }

  #pageSizePanelPage6TextBox {
    float: none;
    margin-top: 11%;
    width: 27%;
    margin-left: 8%;
  }

  #classicCoverImage-slide-7, #drinksCoverImage-slide-8, #healthyCoverImage-slide-9, #partyCoverImage-slide-10, #pastaCoverImage-slide-11, #veggieCoverImage-slide-12 {
    width: 15.967%;
  }

  #pageSizePanelLeftpage6BottomLeftImagesContainerRight {
    margin-right: 0;
    margin-top: 3.5%;
    margin-bottom: 7%;
    width: 100%;
  }

  #csPartyFood17Image-slide-1, #csPartyFood3233Image-slide-2, #csHealthySnacks3637Image-slide-4, #csClassics4041Image-slide-5 {
    width: 48%;
  }

  #csPartyFood17Image-slide-1, #csHealthySnacks3637Image-slide-4 {
    margin-right: 4%;
  }

  #pageSizePanelLeftpage6BottomLeftImagesContainerLeft {
    width: 50%;
    float: left;
  }

  #infoGraphicsImagesLeftContainer,
  #infoGraphicsImagesRightContainer,
  #pageSizePanelPage18TextBox {
    width: 76.5%;
    margin: 0 auto;
  }

  #infoGraphicsImagesRightContainer {
    float: none;
  }

  #pageSizePanelPage18TextBox {
    float: none;
    margin-bottom: 0.5%;
  }

  #infoGraphicsImagesLeftContainer {
    margin-top: 14%;
  }

  #infoGraphicsImagesRightContainer {
    margin-top: 1.5%;
    margin-bottom: 13%;
  }

  #pageSizePanelLeftPage16,
  #pageSizePanelRightPage16 {
    margin: 0 auto;
    width: 76.5%;
  }

  #pageSizePanelRightPage14 {
    margin-bottom: 7%;
  }

  #clovellyGuideBookRecipeBook3ImageContainer {
    margin-bottom: 7vh;
  }

  #pageSizePanelLeftPage16ImageRowTop {
    margin-top: 7vh;
    margin-right: 0;
    width: 74%;
  }

  #pageSizePanelLeftPage16ImageRowBottom {
    margin-bottom: 1.5vh;
    margin-right: 0;
    width: 74%;
    margin-top: 1%;
  }

  #clovellyGuideBookRecipeBook3ImageContainer {
    margin: 0 auto;
    margin-top: 1.5vh;
    width: 98%  ;
    float: none;
  }

  #clovellyGuideBookRecipeBook2ImageContainer, #clovellyGuideBookRecipeBook5ImageContainer {
    margin-right: 1%;
  }

  #clovellyLeafletsNewsletterLeftBottomContainerBothImages,
  #clovellyLeafletsNewsletterLeftTopContainerBothImages,
  #clovellyLeafletsNewsletterRightTopContainerBothImages,
  #clovellyLeafletsNewsletterRightBottomContainerBothImages {
    width: 76.5%;
    float: none;
    margin: 0 auto;
  }

  #clovellyLeafletsNewsletterLeftTopContainerBothImages {
    margin-top: 10vh;
  }

  #clovellyLeafletsNewsletterRightTopContainerBothImages {
    margin-top: 6vh;
  }

  #clovellyLeafletsNewsletterRightBottomContainerBothImages {
    margin-top: 5vh;
  }

  #clovellyVillageNewsletterTextMid {
    width: 102%;
  }

  #hurstCastleDescriptionQuote {
    margin-top: 34%;
  }

  #clovellyLeafletsNewsletterLeftTopContainer {
    margin-bottom: -8%;
  }

  #pageSizePanelPage16TextBoxTop {
    width: 100%;
  }

  #infoGraphicsDescriptionMid {
    width: 100%;
    margin-left: 0%;
  }

  #infoGraphicsDescriptionBottom {
    width: 100%;
  }

  #pageSizePanelPage16TextBox {
    margin-top: 12%;
  }

  #infoGraphicsDescriptionBottom {
    margin-top: 103%;
  }

  #clovellyLeafletsNewsletterLeftBottomContainerRight {
    margin-top: -5%;
  }
}



@media (max-width: 1650px) {
  #logoPage2Box {
    overflow: auto;
    width: 76.5%;
    margin: 0 auto;
    margin-bottom: 19vh;
    margin-top: 19vh;
  }

  #aboutMeContainerLeft h1 {
    margin-top: 12.5vh;
    margin-left: 29%;
  }

  #aboutMeFontContainer {
    margin-top: 12.5vh;
  }

  #aboutMeImage {
    width: 11em;
    margin-left: 29%;
  }

  #aboutMeFont {
    width: 11.2em;
    margin-left: 29%;
  }
}



@media (max-width: 1200px) {

  #aboutMeContainerLeft h1 {
    margin-left: 23%;
  }

  #aboutMeImage,
  #aboutMeFont {
    margin-left: 23%;
  }
}



@media (max-width: 1100px) {
  #aboutMeContainerLeft h1 {
    margin-top: 3em;
    margin-left: 18%;
  }

  #aboutMeFontContainer {
    margin-top: 9em;
  }

  #aboutMeImage,
  #aboutMeFont {
    margin-left: 18%;
  }
}




@media (max-width: 1000px) {
  #aboutMeContainerLeft {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    margin-top: 5vh;
    margin-bottom: 3vh;
  }

  #aboutMeContainerRight {
    margin: 0 auto;
    margin-bottom: 6vh;
    margin-top: 5vh;
    width: 88%;
    float: none;
  }

  #aboutMeContainerLeft h1,
  #aboutMeFontContainer {
    margin-left: 0;
    margin-top: 0;
  }

  #aboutMeImage,
  #aboutMeFont {
    margin-left: 0;
    margin-top: 0.5vh;
  }
}

@media (min-width: 1367px) and (max-width: 1800px) {
    #unstableEarthDescription {
        margin-top: 0;
    }
}

@media only screen and (max-device-width: 1366px) {
  #pageSizePanelLeftRightContainerPage2,
  #page3,
  #page8,
  #page12,
  #pageSizePanelLeftRightContainerPage19 {
    background-attachment: scroll;
  }

  #pageSizePanel-page2 {    
    min-height: 100vh;
  }

  #logoPage2Box {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }

  #pageSizePanelLeftRightContainer {
    margin-bottom: 0;
  }
}



























/* iPad Landscape */

@media (min-width: 1023px) and (max-width: 1366px) and (min-height: 665px) and (max-height: 1024px) {
  .pageSizePanelLeftClickToEnlargeContainer {
    margin-top: 14%;
  }

  #sbLogo1 {
    margin-top: 19%;
    /*width: 35vw;*/
  }

  #sbLogo2 {
    margin-top: 19%;
    /*width: 35vw;*/
  }

  #logoPage2Box {
    margin-top: 14vh;
    margin-bottom: 14vh;
  }

  #page11 {
    margin-bottom: 7%;
    margin-top: 10.5%;
  }

  #page13 {
    margin-top: 3.5%;
  }

  #page14 {
    margin-top: 3.5%;
  }

  #page15 {
    margin-bottom: 7%;
  }

  #page16 {
    margin-bottom: 14%;
  }

  #page17 {
    margin-bottom: 7%;
  }

  #page18 {
    margin-bottom: 7%;
  }

  #hygge2627Container {
    width: 79%;
  }

  #pageSizePanelPage11TextBox {
    margin-left: 14%;
  }

  #pageSizePanelPage15TextBox {
    margin-top: 3.5%;
  }

  #pageSizePanelPage16TextBox {
    margin-top: 7%;
  }

  #infoGraphicsDescriptionBottom {
    margin-top: 134%;
  }

  #clovellyVillageNewsletterTextMid {
    margin-top: 46%;
  }

  #clovellyLeafletsNewsletterLeftBottomContainerLeft {
    width: 40%;
  }

  #pageSizePanel-page2 {
    min-height: fit-content;
    height: fit-content;
  }

  #aboutMeContainerLeft {
    margin-left: 0;
    margin-top: 4vh;
  }

  #aboutMeImage,
  #aboutMeFont {
    width: 11em;
  }
  
  #page11 {
    margin-bottom: 14%;
  }

  #pageSizePanelLeftPage9ImagesContainer {
    margin-top: 14%;
  }

  #pageSizePanelLeftPage13ImageAndTextContainer {
    margin-top: 14%;
  }
}





/* iPad Portrait */
@media (max-height: 1366px) and (max-width: 1024px) and (min-height: 922px) and (min-width: 768px) {
  #sbLogo1Container {
   /* margin-top: 41%;*/
    /*margin-top: 25vh;*/
  }

  #logoPage2Box {
    margin-bottom: 18vh;
    margin-top: 18vh;
  }

  #aboutMeContainerLeft {
    margin-left: 0%;
    margin-top: 6vh;
  }

  #page5,
  #page7,
  #pageSizePanel-page6 {
    margin-top: 14%;
  }

  #page4,
  #page9{
    margin-top: 10.5%;
  }

  #page10 {
    margin-top: 7%;
  }

  #page11 {
    margin-bottom: 21%;
    margin-top: 21%;
  }

  #page13 {
    margin-top: 7%;
  }
  
  #page14 {
    margin-bottom: 14%;
    margin-top: 14%;
  }
  
  #page15 {
    margin-bottom: 7%;
    margin-top: 0;
  }

  #pageSizePanel-page11 {
    min-height: fit-content;
  }

  #silburyHillBook188FinalImageContainer {
    margin-top: 7%;
  }

  #page10,
  #pageSizePanel-page10,
  #page14,
  #pageSizePanel-page14,
  #page15,
  #pageSizePanel-page15,
  #page17,
  #pageSizePanel-page17 {
    min-height: fit-content;
    height: fit-content;
  }

  #sbLogo1 {
    width: 40vw;
  }

  #sbLogo2 {
    width: 40vw;
  }

  #hurstCastleDescriptionTop {
    margin-top: 4.5%;
  }

  #infoGraphicsDescriptionBottom {
    margin-top: 114%;
  }

  #pageSizePanelPage17TextBox {
    margin-top: 76%;
  }

  #page18 {
    margin-top: 14%;
  }

  #sbLogo2Container {
    margin-top: 30vh;
  }
}


@media (max-width: 767px) {
  #logoPage2Box {
    margin-bottom: 8vh;
    margin-top: 8vh;
  }
}
































































@media only screen and (min-width: 1921px) {

  #unstableEarthDescription {
    margin-top: -1%;
  }

  #aboutMeContainerRight,
  #action-force-description-top,
  #pageSizePanelRightTextBoxTop p,
  #action-force-description-bottom,
  #pageSizePanelPage6TextBox p,
  #pageSizePanelPage9TextBox p,
  #pageSizePanelPage10TextBox p,
  #pageSizePanelPage11TextBox p,
  #pageSizePanelPage13TextBox p,
  #pageSizePanelPage14TextBox p,
  #pageSizePanelPage15TextBox p,
  #pageSizePanelPage16TextBox p,
  #pageSizePanelPage17TextBox p,
  #pageSizePanelPage18TextBox p {
    font-size: 1.5em;
  }
  
  #pageSizePanelRightTextBoxTop p strong,
  #pageSizePanelPage6TextBox p strong,
  #pageSizePanelPage9TextBox p strong,
  #pageSizePanelPage10TextBox p strong,
  #pageSizePanelPage11TextBox p strong,
  #pageSizePanelPage13TextBox p strong,
  #pageSizePanelPage14TextBox p strong,
  #pageSizePanelPage15TextBox p strong,
  #pageSizePanelPage16TextBox p strong,
  #pageSizePanelPage17TextBox p strong,
  #pageSizePanelPage18TextBox p strong {
    font-size: 1.1em;
  }

  #panelContentContainerTextContainer h1 {
    font-size: 2.7em;
  }

  #pageSizePanelPage16TextBoxTop {
    width: 56%;
  }

  #infoGraphicsDescriptionMid {
    margin-left: 22%;
    width: 74%;
  }

  #infoGraphicsDescriptionBottom {
    margin-left: 22%;
  }

  #clovellyLeafletsNewsletterLeftBottomContainerLeft {
    width: 33%;
  }
}











#loadingCopy {
  padding-top: 200vh;
}








































































































































































































































































