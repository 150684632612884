@media only screen 
and (min-width : 375px) 
and (max-width : 667px) 
and (min-height : 375px) 
and (orientation : landscape) {
  #pageSizePanel-page1,
  #pageSizePanel-page2 {
    min-height: fit-content;
    height: 100vh;
  }

  #page4,
  #page5,
  #page6,
  #page7,
  #page9,
  #page10,
  #page11,
  #page13,
  #page14,
  #page15,
  #page16,
  #page18 {
    margin-top: 10.5%;
  }

  #page17    {
    margin-top: 7%;
  }


  #page7 {
    margin-bottom: 7%;
  }
  #page11 {
    margin-bottom: 10.5%;
  }

  #page10 {
    margin-top: 3.5%;
  }

  #logoPage2Box {
    margin-bottom: 14%;
  }

  #page3 h1, #page8 h1, #page12 h1 {
    padding-top: 2%;
  }

  .headingPanel {
    height: 40.91vh;
  }

  #page11 {
    margin-top: 14%;
  }

  #clovellyLeafletsNewsletterLeftBottomContainerLeft {
    width: 38%;
  }

  #pageSizePanelPage17TextBox {
    margin-top: 45%;
  }

  #clovellyVillageNewsletterTextMid {
    margin-top: 19%;
  }

  #clovellyLeafletsNewsletterRightTopContainer {
    margin-top: 7%;
  }

  #clovellyLeafletsNewsletterRightBottomContainerBothImages {
    margin-top: 10vh;
  }

  #pageSizePanelPage11TextBox {
    margin-left: 14%;
  }

  #pageSizePanelPage13TextBox {
    margin-top: 9%;
  }

  #hurstCastleDescriptionTop {
    margin-top: 2.5%;
  }

  #hurstCastleDescriptionBottom {
    margin-top: 3.5%;
  }

  #hurstCastleDescriptionQuote {
    margin-top: 17.5%;
  }

  #pageSizePanelPage6TextBox {
    margin-top: 7%;
    width: 42%;
  }

  #sbLogo1ContainerRow {
    margin-top: 28vh;
  }

  #logoPage2Box {
    margin-bottom: 21%;
    margin-top: 23vh;
  }

  #panelContentContainerTextContainer {
    margin-top: 10.5%;
  }

  #pageSizePanelPage16TextBox {
    margin-top: 5%;
  }

  #infoGraphicsDescriptionBottom {
    margin-top: 54%;
  }

  #pageSizePanelLeftPage16ImageRowTop,
  #pageSizePanelLeftPage16ImageRowBottom {
    width: 72%;
  }

  #clovellyLeafletsNewsletterRightTopContainer {
    overflow: visible;
  }

  #clovellyLeafletsNewsletterRightBottomContainer {
    margin-top: 7%;
  }

  #clovellyLeafletsNewsletterRightBottomContainerLeft,
  #clovellyLeafletsNewsletterRightBottomContainerRight {
    margin-top: 7%;
  }
} 

@media only screen 
and (max-width: 414px) 
and (max-height: 896px) {
  #sbLogo1 {
    width: 80%;
  }

  #sbLogo1ContainerRow {
    margin-top: 26vh;
  }

  #pageSizePanel-page2 {    
    min-height: 100vh;
    height: fit-content;
  }

  #logoPage2Box {
    margin-bottom: 10vh;
  }

  #action-force-special-forces-slide-2, #action-force-police-units-slide-3, #action-force-armed-services-slide-4, #action-force-border-defence-slide-5, #action-force-air-force-slide-6, #action-force-army-slide-7, #action-force-special-operations-slide-8, #action-force-navy-slide-9 {
    width: 46%;
    margin: 0 auto;
    margin-right: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    margin-top: 1%;
  }

  #pageSizePanelRightImageGridTopRow {
    margin-bottom: 0.5%;
  }

  #pageSizePanelRightImageGrid {
    width: 95%;
  }

  #action-force-SpecialForces20-21-container {
    width: 95%;
  }

  .pageSizePanelClickToEnlarge {
    color: transparent;
  }

  #pageSizePanelRightTextBoxTop, 
  #pageSizePanelRightTextBoxBottom {
    margin: 0 auto;
    width: 100%;
  }

  #action-force-description-bottom {
    margin-top: 7.7%;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 14%;
  }

  #actionForceTextContainer {
    width: 100%;
  }

  #action-force-description-top {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 7%;
    margin-top: 7%;
  }

  #pageSizePanelRightImageGrid {
    margin-bottom: 35%;
  }

  #action-force-SpecialForces20-21-container {
    margin-top: 28%;
  }

  #whatHappensCoverContainerLeftLeft {
    display: block;
    float: none;
    margin: 0 auto;
    width: 95%;
  }

  .whatHappensCoverContainer {
    display: block;
    width: 100%;
  }

  #whatHappensCoverContainerLeftRight {
    float: none;
    margin: 0 auto;
    width: 89%;
    display: block;
    overflow: visible;
  }

  #whatHappensCoverContainerLeftRightTop {
    float: left;
    width: 67%;
    overflow: visible;
  }

  #darkPastContainerLeftRightTop {
    float: left;
    width: 50%;
    overflow: visible;
  }

  #uEOzoneCover-slide-1 {
    width: 92%;
    margin: 0 auto;
    display: block;
    margin-top: 4%;
    margin-bottom: 4%;
  }

  #uERainforestsCover-slide-6 {
    width: 96%;
    margin: 1%;
  }

  #whatHappensCoverContainerLeftRightBottom {
    overflow: visible;
    display: inline-block;
    width: 33%;
  }

  #darkPastContainerLeftRightBottom {
    overflow: visible;
    display: inline-block;
    width: 50%;
  }

  #uEIceCapcover-slide-3 {
    margin-left: 0;
  }

  #unstableEarthDescription {
    width: 88%;
    margin: 0 auto;
  }

  #whatHappensCoverContainerRightTopRow, #whatHappensCoverContainerRightBottomRow {
    margin: 0 auto;
    width: 88%;
    overflow: visible;
  }

  #ozone45-slide-4,
  #oceans2425-slide-5 {
    margin-bottom: 2%;
    margin-top: 2%;
    width: 100%;
  }
  
  #iceCaps2223-slide-7, #iceCaps2425-slide-8 {
    width: 48.5%;
  }

  #whatHappensCoverContainerRight {
    margin-bottom: 42%;
  }

  #unstableEarthDescription {
    margin-bottom: 7%;
    margin-top: 7%;
  }

  #pageSizePanelLeftpage6TopLeftImagesContainer {
    width: 88%;
  }

  #csPartyFood3839ImageContainer {
    width: 88%;
  }

  #pageSizePanelLeftpage6BottomLeftImagesContainerLeft {
    width: 100%;
  }

  #classicCoverImage-slide-7, #drinksCoverImage-slide-8, #healthyCoverImage-slide-9, #partyCoverImage-slide-10, #pastaCoverImage-slide-11, #veggieCoverImage-slide-12 {
    width: 30.2%;
  }

  #classicCoverImage-slide-7,
  #drinksCoverImage-slide-8,
  #partyCoverImage-slide-10,
  #pastaCoverImage-slide-11 {
    margin-right: 4.66%;
  }

  #healthyCoverImage-slide-9,
  #veggieCoverImage-slide-12 {
    margin-right: 0;
  }

  #partyCoverImage-slide-10 {
    margin-top: 2.6%;
  }

  #pageSizePanelPage6TextBox {
    margin-left: 0;
    width: 100%;
    margin-bottom: 7%;
    margin-top: 7%;
  }

  #pageSizePanelLeftpage6BottomLeftImagesContainerRight {
    margin-bottom: 49%;
  }

  #darkPastContainerLeft {
    width: 88%;
  }

  #darkPastContainerLeftLeft {
    width: 100%;
  }

  #darkPastContainerLeftRight {
    margin: 0 auto;
    width: 100%;
    float: none;
  }

  #ghostsCover63-slide-2 {
    margin-left: 0;
    margin-bottom: 1%;
    margin-top: 5%;
  }

  #darkPastContainerRightLeft {
    width: 100%;
  }

  #darkPastContainerRight {
    width: 88%;
  }

  #vikingCover66-slide-3 {
    margin-left: 0;
    width: 96%;
  }

  #witchesCover64-slide-6 {
    width: 96%;
    margin-top: 4%;
  }

  #darkPastContainerRightLeft {
    margin-top: 1%;
  }

  #aliensCover6-slide-5 {
    margin-bottom: 1%;
    margin-top: 5%;
  }

  #darkPastContainerRightRight {
    margin: 0 auto;
    margin-top: -1%;
    float: none;
    width: 99%;
  }

  #pageSizePanelRightPage7 {
    margin-bottom: 42%;
  }

  #darkPastDescription {
    margin: 0 auto;
    width: 88%;
    margin-bottom: 7%;
    margin-top: 7%;
  }

  #pageSizePanelLeftPage9ImagesContainer {
    width: 95%;
  }

  #calligraphy100101-slide-1,
  #calligraphy6263-slide-2,
  #calligraphy4041-slide-3,
  #calligraphy2021-slide-4 {
    display: block;
    float: none;
    margin: 0 auto;
    width: 88%;
    margin-top: 7%;
    margin-bottom: 7%;
  }

  #pageSizePanelLeftPage9ImagesContainer {
    margin-top: 28%;
  }

  #calligraphy4041-slide-3 {
    margin-top: 0;
  }

  #pageSizePanelPage9TextBox {
    float: none;
    margin: 0 auto;
    width: 100%;
  }

  #calligraphyDescription {
    float: none;
    margin: 0 auto;
    width: 88%;
  }

  #calligraphy4041Container {
    margin-top: 1%;
    width: 95%;
  }

  #pageSizePanelRightPage9 {
    margin-bottom: 42%;
  }

  #hygge2627Container {
    overflow: visible;
    width: 88%;
  }

  #pageSizePanelPage10TextBox {
    margin-top: 3.5%;
    width: 88%;
  }

  #hyggeDescription {
    margin-bottom: 1.5%;
  }

  #hygge5253hygge1011Container {
    overflow: visible;
    width: 100%;
  }

  #hygge5253hygge1011ContainerTop,
  #hygge5253hygge1011ContainerBottom {
    display: block;
    float: none;
    margin: 0 auto;
    overflow: visible;
    width: 88%;
  }

  #hygge5253-slide-2 {
    margin-bottom: 7%;
    margin-top: 7%;
  }

  #pageSizePanelRightPage10 {
    margin-bottom: 42%;
  }

  #pageSizePanelLeftPage11 {
    width: 100%;
  }

  #silburyHillBook188FinalImageContainer {
    margin-bottom: 7%;
    width: 88%;
  }

  #silburyHillBook188FinalImage-slide-1 {
    display: block;
    float: none;
    margin: 0 auto;
    width: 97.5%;
  }

  #pageSizePanelPage11TextBox {
    margin-bottom: 7%;
    width: 86%;
  }

  #pageSizePanelRightPage11 {
    width: 88%;
  }

  #pageSizePanel-page11 {
    min-height: fit-content;
    height: fit-content;
  }

  #page11 {
    margin-bottom: 42%;
  }

  #pageSizePanelLeftPage13 {
    overflow: visible;
    width: 88%;
  }

  #pageSizePanelLeftPage13ImageAndTextContainer {
    margin: 0 auto;
    margin-top: 21%;
    width: 100%;
  }

  #seriesCoverDesigns4ImageContainer {
    margin-bottom: 7%;
    margin-top: 7%;
    width: 99%;
  }

  #pageSizePanelPage13TextBox {
    width: 100%;
    margin-top: 7%;
  }

  #seriesCoverDesigns2Image-slide-1 {
    display: block;
    margin: 0 auto;
    width: 96%;
  }

  #pageSizePanelRightPage13 {
    overflow: visible;
    width: 88%;
  }

  #navigatorGuidesSampleSpread1213Image-slide-4,
  #navigatorGuidesSampleSpread1415Image-slide-7 {
    display: block;
    float: none;
    margin: 0 auto;
    width: 100%;
  }

  #navigatorGuidesSampleSpread1415Image-slide-7 {
    margin: 0 auto;
    width: 100%;
  }

  #navigatorGuidesSampleSpread1415ImageContainer {
    display: block;
    margin: 0 auto;
    width: 98.5%;
  }

  #page13 {
    height: fit-content;
  }

  .pageSizePanelLeftRightContainer,
  #pageSizePanelRightPage13 {
    height: fit-content;
    min-height: fit-content;
  }

  .pageSizePanelLeft, .pageSizePanelRight {
    height: fit-content;
    min-height: fit-content;
  }

  #pageSizePanel-page13 {
    height: fit-content;
    min-height: fit-content;
  }

  #navigatorGuidesSampleSpread1415ImageContainer {
    display: block;
    float: none;
    margin: 0 auto;
    margin-top: 9%;
    margin-bottom: 49%;
  }

  #seriesCoverDesigns3Image-slide-2, #seriesCoverDesigns1Image-slide-3, #seriesCoverDesigns6Image-slide-5, #seriesCoverDesigns4aImage-slide-6 {
    margin: 0 auto;
    width: 45%;
  }

  #seriesCoverDesigns1Image-slide-3,
  #seriesCoverDesigns4aImage-slide-6 {
    margin-left: 7.5%;
  }

  #seriesCoverDesigns3Image-slide-2,
  #seriesCoverDesigns1Image-slide-3 {
    margin-bottom: 3.5%;
  }

  #pageSizePanelLeftPage14ImageContainer {
    display: block;
    float: none;
    overflow: visible;
    padding: 0;
    width: 88%;
  }

  #pageSizePanelLeftPage14ImageContainerLeft,
  #pageSizePanelLeftPage14ImageContainerRight {
    float: none;
    overflow: visible;
    padding: 0;
    width: 100%;
  }

  #wells1415Image-slide-1,
  #wells1011Image-slide-2,
  #wellsCoverImage-slide-3,
  #wellsPage12Image-slide-4,
  #page30Image-slide-5 {
    margin-bottom: 7%;
    margin-left: 1%;
    width: 99%;
  }

  #page30Image-slide-5 {
    margin-bottom: 0;
  }

  #wells1011Image-slide-2 {
    margin-bottom: 3.5%;
  }

  #pageSizePanelRightPage14ImageContainerLeft, #pageSizePanelRightPage14ImageContainerRight, #pageSizePanelRightPage14ImageContainerCentre {
    float: none;
    overflow: visible;
    padding: 0;
    width: 100%;
  }

  #pageSizePanelRightPage14ImageContainer {
    margin-bottom: 35%;
    overflow: visible;
    width: 88%;
  }

  #pageSizePanelPage14TextBox {
    margin-bottom: 7%;
    margin-top: 7%;
    width: 88%;
  }

  #pageSizePanelRightPage14 {
    overflow: visible;
  }

  #pageSizePanelPage15TextBox {
    width: 100%;
  }

  #pageSizePanelLeftPage15TopRow,
  #pageSizePanelLeftPage15BottomRow {
    overflow: visible;
    width: 88%;
  }

  #coverV6ImageContainer,
  #hurstCastle23ImageContainer {
    width: 100%;
  }

  #hurstCastleDescriptionQuote {
    margin-top: 10.5%;
    width: 100%;
  }

  #hurstCastle1415ImageContainer,
  #hurstCastle1617ImageContainer {
    display: block;
    float: none;
    width: 100%;
  }

  #hurstCastle1415Image-slide-4 {
    margin-top: 7%;
  }
  
  #pageSizePanelPage15TextBox {
    margin-top: 3.5%;
  }


  #hurstCastle1617ImageContainer {
    width: 88%;
    float: none;
    margin: 0 auto;
    overflow: visible;
  }

  #hurstCastle1617Image-slide-3 {
    display: block;
    float: none;
    margin: 1%;
    width: 100%;
  }

  #pageSizePanelRightPage15 {
    overflow: visible;
  }

  #page15 {
    margin-bottom: 42%;
  }

  #hurstCastle23Image-slide-2 {
    margin-bottom: 7%;
    margin-top: 7%;
  }

  #hurstCastleDescriptionTop,
  #pageSizePanelPage15TextBox {
    margin-top: 0;
  }

  #pageSizePanelLeftPage16,
  #pageSizePanelRightPage16 {
    overflow: visible;
    width: 88%;
  }

  #pageSizePanelLeftPage16ImageRowTop,
  #pageSizePanelLeftPage16ImageRowBottom {
    width: 100%;
  }

  #clovellyGuideBookRecipeBook2ImageContainer,
  #clovellyGuideBookRecipeBook5ImageContainer {
    margin-right: 0;
  }

  #pageSizePanelLeftPage16ImageRowBottom {
    margin-top: 3.5%;
    overflow: visible;
  }

  #clovellyGuideBookRecipeBook3ImageContainer {
    width: 100%;
  }

  #page16 {
    margin-bottom: 42%;
  }

  #pageSizePanelPage16TextBox {
    margin-top: 7%;
    width: 100%;
  }

  #pageSizePanelLeftPage16ImageRowBottom {
    margin-top: 28%;
  }

  #infoGraphicsDescriptionBottom {
    margin-top: 0;
    width: 88%;
  }

  #pageSizePanelPage16TextBox {
    margin-top: 3.5%;
  }

  #pageSizePanelLeftPage16ImageRowBottom {
    margin-bottom: 0;
  }

  #infoGraphicsDescriptionBottom {
    margin-bottom: 49%;
  }

  #clovellyLeafletsNewsletterLeftBottomContainerBothImages, #clovellyLeafletsNewsletterLeftTopContainerBothImages, #clovellyLeafletsNewsletterRightTopContainerBothImages, #clovellyLeafletsNewsletterRightBottomContainerBothImages {
    width: 88%;
    float: none;
    margin: 0 auto;
  }

  #clovellyLeafletsNewsletterImage1-slide-1 {
    margin-left: 9.5%;
    margin-top: 21%;
  }

  #clovellyLeafletsNewsletterLeftBottomContainerRight {
    margin-top: 0;
    width: 50%;
  }

  #clovellyLeafletsNewsletterLeftBottomContainerLeft {
    width: 46%;
  }

  #pageSizePanelLeftPage17 {
    overflow: visible;
  }

  #clovellyLeafletsNewsletterLeftTopContainerBothImages{
    overflow: visible;
    margin-top: 3%;
  }

  #clovellyLeafletsNewsletterRightTopContainerNarrow {
    width: 107%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: visible;
    margin-top: 7%;
  }

  #pageSizePanelPage17TextBox {
    margin-top: 0;
    width: 100%;
    display: block;
  }

  #clovellyLeafletsNewsletterLeftTopContainerRightImage {
    display: block;
    float: none;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 14%;
  }

  #clovellyLeafletsNewsletterLeftTopContainerBothImages {
    height: fit-content;
  }

  #pageSizePanelPage17TextBox {
    margin-bottom: 7%;
    margin-top: 7%;
  }

  #clovellyLeafletsNewsletterLeftTopContainerLeftImage {
    width: 100%;
  }

  #clovellyLeafletsNewsletterImage1-slide-1 {
    transform: rotate(0deg);
    margin-left: 0;
    margin-bottom: 7%;
    margin-top: 0;
  }

  #clovellyLeafletsNewsletterRightBottomContainerBothImages {
    margin-top: 35%;
  }

  #clovellyLeafletsNewsletterLeftBottomContainerRight {
    width: 100%;
  }

  #clovellyLeafletsNewsletterImage6-slide-6,
  #clovellyLeafletsNewsletterImage7-slide-7 {
    margin-bottom: 14%;
  }

  #clovellyLeafletsNewsletterLeftBottomContainerRight {
    margin-bottom: 42%;
  }

  #infoGraphicsImagesLeftContainer {
    margin-bottom: 72%;
    width: 88%;
  }

  #infoGraphicsImagesLeftContainerLeft {
    margin-right: 0;
    width: 50%;
  }

  #infoGraphicsImagesLeftContainerCentre {
    float: right;
    margin-right: 0;
    width: 45.2%;
  }

  #infoGraphics1Image-slide-1, 
  #infoGraphics2Image-slide-2 { 
    width: 100%;
  }

  #pageSizePanelPage18TextBox {
    width: 88%;
  }

  #infoGraphicsImagesRightContainer {
    width: 88%;
  }

  #infoGraphicsImagesRightContainerLeft {
    margin: 0;
  }


  #infoGraphicsImagesRightContainerLeft, 
  #infoGraphicsImagesLeftContainerRight {
    width: 47%;
  }

  #infoGraphicsImagesRightContainerCentre {
    margin: 0;
    width: 48%;
  }

  #infoGraphicsImagesRightContainerRight {
    width: 47%;
  }

  #pageSizePanelRightpage18 {
    margin-top: 3.5%;
  }
}